import React, { useEffect } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Privacy from "../components/Privacy"
import StaticPageHeader from '../components/StaticPageHeader'

const PrivacyPolicy = () => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.body.classList.remove('dots')
    }
  }, [])

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/privacy-policy",
      "@type": "webpage",
      "name": "Hubble Contacts Privacy Policy | Hubble",
      "description": "Read the most up-to-date privacy policy applicable to visitors of the hubblecontacts.com website. Learn about how your information may be collected, and the choices you have regarding such information.",
      "publisher": {
        "@type": "Organization",
        "name": "Hubble Contacts"
      }
    }
  ]


  return (
    <Layout>
      <SEO title="Hubble Contacts Privacy Policy"
        description="Read the most up-to-date privacy policy applicable to visitors of the hubblecontacts.com website. Learn about how your information may be collected, and the choices you have regarding such information."
        schemaMarkup={schemaMarkup}
      />
      <StaticPageHeader title="Privacy Policy" subheader="Vision Path, Inc. | Hubble Contacts"/>
      <Privacy />
    </Layout>
  )
}

export default PrivacyPolicy
